royal.layoutHelper = (function($) {
  "use strict";

  var size;

  var bodyIsOverflowing = function() {
    var fullwidth = window.innerWidth
    // console.log('fullwidth:', fullwidth);
    var clientwidth = document.body.clientWidth
    // console.log('clientwidth:', clientwidth)
    return clientwidth < fullwidth
  }

  var controlBodyOverlay = function($target, offsetFn) {

    if (!size) {
      var scrollDiv = document.createElement('div')
      scrollDiv.style.position = 'absolute'
      scrollDiv.style.top = '-9999px'
      scrollDiv.style.width = '50px'
      scrollDiv.style.height = '50px'
      scrollDiv.style.overflow = 'scroll'
      document.body.appendChild(scrollDiv)
      size = scrollDiv.offsetWidth - scrollDiv.clientWidth
      document.body.removeChild(scrollDiv)
    }

    var overflow = bodyIsOverflowing()

    $target.addClass('overlay-active')

    if (overflow) {

      if (offsetFn) {
        offsetFn(size)
      } else {
        $target.css('padding-right', size)
      }

    }
  }

  var clearBodyOverlay = function(target, overlayLeaveDuration, clearFn) {
    setTimeout(function() {
      target.removeClass('overlay-active')
      if (clearFn) {
        clearFn()
      } else {
        target.css('padding-right', 0)
      }
    }, overlayLeaveDuration)
  }

  return {controlBodyOverlay: controlBodyOverlay, clearBodyOverlay: clearBodyOverlay}

})(jQuery)

royal.contact = (function ($) {
    "use strict";

    function init() {

        if ($('.modal__contact').length === 0)
            return

        var $layoutElm = $('.story-page, .global-header')
        var $layoutWrapElm = $('.story-page')
        var $layoutGlobalHeaderElm = $('.global-header')

        var targetCaller

        var openModal = function (e) {

            targetCaller = e

            royal.layoutHelper.controlBodyOverlay($layoutElm, function (size) {
                $layoutGlobalHeaderElm.css('right', size)
                $layoutWrapElm.css('padding-right', size)
            })

            $('body').addClass('overflow--hidden');
            $('.modal__contact, .modal__close, .history--indicatorwrap').addClass('display--modal');
            $('.modal__contact').addClass('unhide--modal');
        }

        // Open modal functionalities
        $('.modal__contact--button').on('click', openModal);

        $("#contactForm").submit(function (e) {

            var url = "/umbraco/surface/ContactService/Submit";

            var data = $("#contactForm").serialize()
            var purposeRequestVal = $("#contactForm").find('input[name=PurposeOfContact]:checked').val();

            $.ajax({
                type: "POST",
                url: url,
                data: data,
                success: function (data) {
                    var parsed = JSON.parse(data);
                    if (parsed !== "Success") {
                        for (var i = 0; i < parsed.length; i++) {
                            if (parsed[i].Value.length > 0) {
                                var inp = $("#contactForm").find("#" + parsed[i].Key)
                                inp.addClass('fd_form__error--show')
                                inp.next('.fd_form__error').addClass('fd_form__error--show').html(parsed[i].Value[0])
                            }
                        }
                    } else {
                        alert(data);
                        royal.tracking.contactModalClick(targetCaller, purposeRequestVal)
                    }
                }
            });
            e.preventDefault();
        });

        var closeModal = function () {

            royal.layoutHelper.clearBodyOverlay($layoutElm, 0, function () {
                $layoutGlobalHeaderElm.css('right', 0)
                $layoutWrapElm.css('padding-right', 0)
            })

            $('body').removeClass('overflow--hidden');
            $('.modal__contact').removeClass('unhide--modal');
            $('.display--modal').removeClass('display--modal');
        }

        // Close modal functionalities
        $('.modal__close').on('click', closeModal)

        $('.contactoverlay__bg').on('click', closeModal)

        // Nav to given element
        $('#contactoverlay-view .historyslider__indicator').on('click', function () {
            var elem = $('.historyslider__item').eq($('.historyslider__indicator').index($(this)));

            $('.modal__contact').animate({
                scrollTop: $('.modal__contact').scrollTop() + elem.offset().top
            }, 500)
        })
    }

    function setIndicators() {
        $('.historyslider__item--wrap').each(function (i) {
            if ($(this).visible(true) && !$(this).hasClass('item--inview')) {

                $(this).addClass('item--inview');

                $('.historyslider__indicator').eq(i).addClass('historyslider__indicator--active')
            }
        })
    }

    return { init: init }

})(jQuery);

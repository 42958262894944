royal.tracking = (function($) {
  "use strict";

  window.dataLayer = [];
  var $debug = false;

  function init() {
    $('#visit-royal-button').on('click', royal.tracking.visitRoyalClick)
  }

  return {
    init: init,

    // Diverse
    visitRoyalClick: function() {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'Visit royalcopenhagen.com',
            "action": 'Click',
            "label": ''
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    contactModalClick: function(e, requestVal) {
      var label = $(e.target).data('track-label')
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'Contact royalcopenhagen',
            "action": 'Contact request sent',
            "label": label + ' - ' + requestVal
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    // The Collection
    collectionImageClick: function(productName) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'Product view',
            "action": 'Click',
            "label": productName
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    goToCollectionTracking: function(pageUrl, progress) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'The Experience',
            "action": 'Click',
            "label": 'See the collection'
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    // The Craft
    goBehindTheSceneClick: function() {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'The Craft',
            "action": 'Click',
            "label": 'Walk the steps'
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    lookingForMoreClick: function() {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'The Craft',
            "action": 'Click',
            "label": 'Learn more'
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    craftReadTheStoryScrollToChapter: function(virtualPageUrl, virtualPageTitle) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "VirtualPageview",
          "virtualPageURL": virtualPageUrl,
          "virtualPageTitle": virtualPageTitle
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    // The Story
    readTheStoryClick: function() {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'The Story',
            "action": 'Click',
            "label": 'Read the story'
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    timelineClick: function(period) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'The Story',
            "action": 'Timeline seen',
            "label": period
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    experienceTracking: function(pageUrl, progress) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'The Story',
            "action": 'Click',
            "label": 'Explore the pieces'
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    // Inspiration slider
    inspirationClick: function(index) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'The Story',
            "action": 'Click',
            "label": 'Immortal Beloved - ' + index
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    // Scroll event
    scrollTracking: function(pageUrl, section) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'Scroll',
            "action": section,
            "label": pageUrl
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    // Video tracking
    videoProgressTracking: function(pageUrl, progress) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'Video - A passion for flowers',
            "action": 'Watched ' + progress,
            "label": pageUrl
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    videoActionTracking: function(pageUrl, action) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'Video - A passion for flowers',
            "action": action,
            "label": pageUrl
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    // The experience
    mailTracking: function() {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'Contact royalcopenhagen',
            "action": 'Contact request sent',
            "label": 'The Experience - Email'
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    },

    motifTracking: function(motif) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "trackEvent",
          "eventData": {
            "category": 'The Experience',
            "action": 'Click',
            "label": motif
          }
        })
      }
      if($debug) console.log('dataLayer:', dataLayer);
    }

  }
})(jQuery);

var royal = royal || {};

(function ($) {

    $(document).ready(function () {
        FastClick.attach(document.body);

        var isMobile = false;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            isMobile = true;
        }

        //for IE 9 to Edge 12
        svg4everybody({ polyfill: true });

        window.bLazy = new Blazy({
            breakpoints: [{
                width: 640 // max-width
                , src: 'data-src-small'
            }, {
                width: 768 // max-width
                , src: 'data-src-medium'
            }, {
                width: 1024 // max-width
                , src: 'data-src-large'
            }, {
                width: 1400 // max-width
                , src: 'data-src-xlarge'
            }],
            loadInvisible: true,
            offset: 1000
        });

       var bLazyModal = new Blazy({ container: '.modal__wrap', loadInvisible: true, offset: 1000 });

        if (isMobile) {
            $('body').addClass('touch-device');
        }

        $('.global-header__burger').on('click', function () {

            $('body').toggleClass('menu-open');
        })

        $('body').on('keydown', function (e) {
            const $modal = $('.modal__close.display--modal');
            if (e.keyCode == 27 && $modal.length) {
                // Esc key == 27
                $modal.trigger('click');
            }
        });


        // Global scroll tracking
        var $sections = $('.tracking-section')

        var scouts = $sections.map(function(i, chapter) {
            var sectionName = $(chapter).data('name')
            return {
                scout: scrollscout.create(chapter),
                sectionName: sectionName
            }
        })

        scouts.each(function(i, sc) {
            var unsub = sc.scout.addTrigger('trigger' + i).subscribe(function() {
                royal.tracking.scrollTracking(window.location.pathname, sc.sectionName)
              unsub()
            })
            sc.scout.update()
            return unsub
        })

        // Experience mail tracking
        // Todo: bundle app and scripts together
        $('.experience-page a[href^=mailto]').on('click', function() {
          royal.tracking.mailTracking();
        })

        // Experience button tracking
        $('.experience-page .button').on('click', function() {
          royal.tracking.goToCollectionTracking();
        })

        // Experience motif tracking
        $('.experience-page .map').on('click', function() {
          royal.tracking.motifTracking($(this).data('target'));
        })

        royal.loader.init();
        royal.history.init();
        royal.newtimelineslider.init();
        royal.inspiration.init();
        royal.plainswiper.init();
        royal.behindscenes.init();
        royal.behindscenesoverlay.init();
        royal.princess.init();
        royal.ncollection.init();
        royal.experience.init();
        royal.contact.init();
        royal.tracking.init();

    });

})(jQuery);

royal.behindscenes = (function($) {
  "use strict";

  function init() {

    new Swiper('.behindscenes__media__swiper.swiper-container--plainswiper', {
      loop: true,
      autoplay: true,
      pagination: {
        el: '.behindscenes__media__swiper-pagination',
        clickable: true
      },
      effect: 'fade',
      speed: 2000
    })

    function clickhandler() {
      $('body').addClass('overflow--hidden');
      $('.modal__behindscenes, .modal__close').addClass('display--modal')
      $('.modal__behindscenes').addClass('unhide--modal')

      // Tracking
      var $firstChapter = $('.behindscenesoverlay__slide').eq(0)
      var virtualUrl = $firstChapter.data('virtual-page-url')
      var virtualTitle = $firstChapter.data('virtual-page-title')
      royal.tracking.craftReadTheStoryScrollToChapter(virtualUrl, virtualTitle)

      royal.tracking.goBehindTheSceneClick()

    }

    $('.behindscenes--button').on('click', clickhandler);

  }

  return {init: init}

})(jQuery);

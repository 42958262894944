royal.behindscenesoverlay = (function($) {
  "use strict";

  var ACTIVE_CLASS = 'behindscenesoverlay__slide--active'
  var ACTIVE_SELECTOR = '.' + ACTIVE_CLASS
  var FULLSCREEN_CLASS = 'behindscenesoverlay__slide--fullscreen'

  /**
	 * DEV NOTICE
	 *
	 * Tracking is outcommented because it's not done
	 *
	 */
  function init() {
    var $behindscenesoverlay = $('.behindscenesoverlay')
    if ($behindscenesoverlay.length === 0)
      return


    var activeIndex 	= 0
    var $pagingSteps 	= $('.behindscenesoverlay .swiper__paging__step')
    var $pagingLinks 	= $('.behindscenesoverlay .swiper__paging__link')
    var $btnPrev 			= $('.behindscenesoverlay .swiper__navbuttons__cell--prev .swiper__navbtn')
    var $btnNext 			= $('.behindscenesoverlay .swiper__navbuttons__cell--next .swiper__navbtn')

    var $gallery 			= $('.behindscenesoverlay__gallery')
    var $slides 			= $('.behindscenesoverlay__gallery .behindscenesoverlay__slide')
    var $currentSlide = $slides.filter(ACTIVE_SELECTOR)
    var $targetSlide

    function prevButtonHandler() {
      if (activeIndex === 0)
        return
      updateSlidesToIndex(activeIndex - 1)
    }

    function nextButtonHandler() {
      if (activeIndex === $slides.length - 1)
        return
      updateSlidesToIndex(activeIndex + 1)
    }

    function pagingClickHandler(e) {
      e.preventDefault()
      var $elm = $(this)
      var index = $elm.data('index')
      updateSlidesToIndex(index)
    }

    function updateSlidesToIndex(index) {

      var direction,
        	targetFullscreen,
        	currentFullscreen;

      if (activeIndex === index)
        return

      $targetSlide = $slides.filter(function() {
        return $(this).data('index') === index
      })
      $currentSlide = $slides.filter(ACTIVE_SELECTOR)

      targetFullscreen = $targetSlide.hasClass(FULLSCREEN_CLASS)
      currentFullscreen = $currentSlide.hasClass(FULLSCREEN_CLASS)

      direction = (activeIndex < index)
      activeIndex = index

      removeHandlers()

      if (targetFullscreen !== currentFullscreen) {
        var paging = $('.behindscenesoverlay__paging')
        TweenMax.to(paging, .2, {
          opacity: 0,
          onComplete: animateSlides
        })
      } else {
        animateSlides()
      }

      if (targetFullscreen) {
        $behindscenesoverlay.addClass('behindscenesoverlay--contentbglayer-fullscreen')
      } else {
        $behindscenesoverlay.removeClass('behindscenesoverlay--contentbglayer-fullscreen')
      }

      // Tracking
      var virtualUrl = $targetSlide.data('virtual-page-url')
      var virtualTitle = $targetSlide.data('virtual-page-title')
      royal.tracking.craftReadTheStoryScrollToChapter(virtualUrl, virtualTitle)

      function animateSlides() {
        $targetSlide.addClass(ACTIVE_CLASS);
        targetFullscreen
          ? animateInFullscreen()
          : animateIn(direction)
        currentFullscreen
          ? animateOutFullscreen()
          : animateOut(!direction)
        if (targetFullscreen) {
          $behindscenesoverlay.addClass('behindscenesoverlay--fullscreen')
        } else {
          $behindscenesoverlay.removeClass('behindscenesoverlay--fullscreen')
        }
        updateNavigation()
        handlePagingAnimationOut()
      }

    }

    function preventDefaultHandler(e) {
      e.preventDefault();
    }

    function adjustDirection(obj, direction) {
      obj.x = direction
        ? obj.x
        : '-' + obj.x
      return obj
    }

    function animateInFullscreen() {
      var media = $targetSlide.find('.behindscenesoverlay__fullscreen__media')
      var description = $targetSlide.find('.behindscenesoverlay__fullscreen__description')
      var note = $targetSlide.find('.behindscenesoverlay__fullscreen__note')
      var slideElements = [media, description, note]

      var slideElementsOutProps = {
        opacity: 0,
        y: '10%'
      }
      var slideElementsInsideProps = {
        opacity: 1,
        y: '0%',
        delay: 0.4
      }
      TweenMax.set(slideElements, slideElementsOutProps)
      TweenMax.staggerTo(slideElements, 0.3, slideElementsInsideProps, 0.2, animationComplete)

    }

    function animateIn(direction) {
      var header = $targetSlide.find('.behindscenesoverlay__header')
      var body = $targetSlide.find('.behindscenesoverlay__body')
      var image = $targetSlide.find('.behindscenesoverlay__image')
      var slideElements = [header, body]

      var slideElementsOutProps = {
        opacity: 0,
        x: '20%'
      }
      var slideElementsInsideProps = {
        opacity: 1,
        x: '0%'
      }
      TweenMax.set(slideElements, adjustDirection(slideElementsOutProps, direction))
      TweenMax.staggerTo(slideElements, 0.3, slideElementsInsideProps, 0.2, animationComplete)

      var imageInsideProps = {
        opacity: 1
      }
      var imageOutsideProps = {
        opacity: 0
      }
      TweenMax.set(image, imageOutsideProps)
      TweenMax.to(image, 0.3, imageInsideProps)

    }

    function animateOutFullscreen() {
      var media = $currentSlide.find('.behindscenesoverlay__fullscreen__media')
      var description = $currentSlide.find('.behindscenesoverlay__fullscreen__description')
      var note = $currentSlide.find('.behindscenesoverlay__fullscreen__note')
      var slideElements = [media, description, note]

      var slideElementsOutProps = {
        opacity: 0,
        y: '10%'
      }
      var slideElementsInsideProps = {
        opacity: 1,
        y: '0%'
      }
      TweenMax.set(slideElements, slideElementsInsideProps)
      TweenMax.staggerTo(slideElements, 0.3, slideElementsOutProps, 0.1)

    }

    function animateOut(direction) {
      var header = $currentSlide.find('.behindscenesoverlay__header')
      var body = $currentSlide.find('.behindscenesoverlay__body')
      var image = $currentSlide.find('.behindscenesoverlay__image')
      var slideElements = [header, body]

      var slideElementsInsideProps = {
        opacity: 1,
        x: '0%'
      }
      var slideElementsOutProps = {
        opacity: 0,
        x: '20%'
      }

      TweenMax.set(slideElements, slideElementsInsideProps)
      TweenMax.staggerTo(slideElements, 0.3, adjustDirection(slideElementsOutProps, direction), 0.1)

      var imageInsideProps = {
        opacity: 1
      }
      var imageOutsideProps = {
        opacity: 0
      }
      TweenMax.set(image, imageInsideProps)
      TweenMax.to(image, 0.3, imageOutsideProps)
    }

    function handlePagingAnimationOut() {
      var targetFullscreen = $targetSlide.hasClass(FULLSCREEN_CLASS)
      var currentFullscreen = $currentSlide.hasClass(FULLSCREEN_CLASS)

      if (targetFullscreen !== currentFullscreen) {
        var paging = $('.behindscenesoverlay__paging')
        TweenMax.to(paging, 0.1, {
          opacity: 1,
          delay: 0.2
        })
      }
    }

    function animationComplete() {
      $currentSlide.removeClass(ACTIVE_CLASS);

      setHandlers()
    }

    function updateNavigation() {
      setPagingIndex(activeIndex)
      $btnPrev.addClass('swiper__navbtn--show')
      $btnNext.addClass('swiper__navbtn--show')
      if (activeIndex === 0) {
        $btnPrev.removeClass('swiper__navbtn--show')
      }

      if (activeIndex === $slides.length - 1) {
        $btnNext.removeClass('swiper__navbtn--show')
      }
    }

    function setPagingIndex(index) {
      var step = $pagingSteps.find('[data-index="' + index + '"]')
      $pagingSteps.removeClass('swiper__paging__step--selected')
      step.parent().addClass('swiper__paging__step--selected')
    }

    function resizeHandler() {
      $slides.css("height", "auto")
      $gallery.css("height", "auto")
      var a = $slides.map(function() {
        return $(this).height()
      })
      var highest = Math.max.apply(Math, a)
      $gallery.css("height", highest)
      $slides.css("height", highest);
    }

    function setHandlers() {
      $pagingLinks.on('click', pagingClickHandler)
      $btnPrev.on('click', prevButtonHandler)
      $btnNext.on('click', nextButtonHandler)
      $behindscenesoverlay.swipe('enable')
    }

    function removeHandlers() {
      $pagingLinks.unbind("click", pagingClickHandler).bind("click", preventDefaultHandler)
      $btnPrev.unbind('click', prevButtonHandler).bind("click", preventDefaultHandler)
      $btnNext.unbind('click', nextButtonHandler).bind("click", preventDefaultHandler)
      $behindscenesoverlay.swipe('disable')
    }

    $(window).on("resize", _.throttle(resizeHandler, 20))

    $behindscenesoverlay.swipe({swipeRight: prevButtonHandler, swipeLeft: nextButtonHandler})

    updateNavigation()
    resizeHandler()
    setHandlers()
    //updateSlidesToIndex(3)

    var $video = $('#modal__behind__video')
    var $pageUrl = '/the-steps-of-creation/made-to-order';

    var $player = new Vimeo.Player($video);
    var $durration = 0;
    var $progress = [
      {
        trackingSend: false
      },{
        trackingSend: false
      },{
        trackingSend: false
      },{
        trackingSend: false
      }
    ];

    // Check if tracking events has been send
    function trackTime(){
      $player.getCurrentTime().then(function(seconds) {
        var percentage = Math.ceil( ((seconds / $durration) * 100) )

        if(percentage >= 25 && !$progress[0].trackingSend){
          $progress[0].trackingSend = true;
          royal.tracking.videoProgressTracking($pageUrl, percentage + '%')
        } else if(percentage >= 50 && !$progress[1].trackingSend){
          $progress[1].trackingSend = true;
          royal.tracking.videoProgressTracking($pageUrl, percentage + '%')
        } else if(percentage >= 75 && !$progress[2].trackingSend){
          $progress[2].trackingSend = true;
          royal.tracking.videoProgressTracking($pageUrl, percentage + '%')
        } else if(percentage >= 100 && !$progress[3].trackingSend){
          $progress[3].trackingSend = true;
          royal.tracking.videoProgressTracking($pageUrl, percentage + '%')
        }
      })
    }

    var initTimeTracking;

    $player.getDuration().then(function(duration) {
      $durration = duration;
    })

    $player.on('play', function() {
        initTimeTracking  = setInterval(trackTime, 250);
        royal.tracking.videoActionTracking($pageUrl,'Play')
    });

    $player.on('pause', function() {
      clearInterval(initTimeTracking)
      royal.tracking.videoActionTracking($pageUrl,'Pause')
    });

    var $allVideos = $video
    var $fluidEl = $(".behindscenesoverlay__fullscreen__video");

    $allVideos.each(function() {
      $(this).data('aspectRatio', 0.5).removeAttr('height').removeAttr('width');
    })

    $(window).resize(function() {
      var newWidth = $fluidEl.width()
      $allVideos.each(function() {
        var $el = $(this)
        $el.width(newWidth).height(newWidth * $el.data('aspectRatio'))
      })

    }).resize()

    // On modal close
    $('.modal__close').on('click', function() {

      $('body').removeClass('overflow--hidden');
      $('.display--modal').removeClass('display--modal')

      // Hide video when the modal is closed and reset view classes
      setTimeout(function() {
        $('.modal__sidecontent, .expand__video, #modal__video').removeClass('display--video')
        $('.modal__behindscenes').removeClass('unhide--modal')

        // Reset video to stop it
        $player.pause()

        // reset modal slider
        updateSlidesToIndex(0)
      }, 500);
    })

  }

  return {init: init}
})(jQuery);

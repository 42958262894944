royal.loader = (function($){
	"use strict";

	function init(){

		var loaded = 0;

		setTimeout(function() {
			if(loaded || $('.loader').length == 0) return

			$('.loader__logo').animate({
				opacity: 1
			},250);

		},1000);

		$(window).load(function() {
			loaded = 1;

			$('.loader').animate({
				opacity: 0
			},500, function() {
				$(this).remove();
			})
		})
	}

	return {
		init:init
	}

})(jQuery);

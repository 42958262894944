royal.princess = (function($) {
  "use strict";

  function init() {
    // Hookup scroll event
    $('.modal__princess').scroll(function(e) {
      if ($('.modal__princess').hasClass('display--modal'))
        setIndicators()
    })

    var $view = $('.modal__princess')[0]

    var $chapters = $('.modal__princess li')

    var scouts = $chapters.map(function(i, chapter) {
      var virtualUrl = $(chapter).data('virtual-page-url')
      var virtualTitle = $(chapter).data('virtual-page-title')
      return {
        scout: scrollscout.create(chapter, $view),
        virtualUrl: virtualUrl,
        virtualTitle: virtualTitle
      }
    })

    // Open modal functionalities
    $('.modal__princess--button').on('click', function() {
      royal.tracking.lookingForMoreClick()
      $('body').addClass('overflow--hidden');

      scouts.each(function(i, sc) {
        var unsub = sc.scout.addTrigger('trigger' + i).subscribe(function() {
          royal.tracking.craftReadTheStoryScrollToChapter(sc.virtualUrl, sc.virtualTitle)
          unsub()
        })
        sc.scout.update()
        return unsub
      })

      $('.modal__princess, .modal__close, .history--indicatorwrap').addClass('display--modal');
      $('.modal__princess').addClass('unhide--modal');

      setTimeout(function() {
        $('.princess--indicatorwrap .historyslider__indicator').each(function(i) {
          var $item = $(this);

          setTimeout(function() {
            $item.animate({
              width: 40
            }, 250);
          }, 150 * i);
        });
      }, 1000);

      requestAnimationFrame(function() {
        requestAnimationFrame(function() {
          window.bLazy.revalidate();
        });
      });
    });

    // Close modal functionalities
    $('.modal__close').on('click', function() {
      $('body').removeClass('overflow--hidden');
      $('.display--modal').removeClass('display--modal')

      $('.historyslider__indicator').animate({
        width: 0
      }, 150);

      setTimeout(function() {
        $('.modal__princess').removeClass('unhide--modal')
      }, 500)

      scouts.each(function(i, sc) {
        sc.scout.removeTrigger('trigger' + i)
      })

    })

    // Nav to given element
    $('#princessoverlay-view .historyslider__indicator').on('click', function() {
      var elem = $('.historyslider__item').eq($('.historyslider__indicator').index($(this)));

      $('.modal__princess').animate({
        scrollTop: $('.modal__princess').scrollTop() + elem.offset().top
      }, 500)
    })
  }

  function setIndicators() {
    $('.historyslider__item--wrap').each(function(i) {
      if ($(this).visible(true) && !$(this).hasClass('item--inview')) {

        $(this).addClass('item--inview');

        $('.historyslider__indicator').eq(i).addClass('historyslider__indicator--active')
      }
    })
  }

  return {init: init}

})(jQuery);

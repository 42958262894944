royal.ncollection = (function($) {
  "use strict";

  function init() {
    if ($('.ncollection').length === 0)
      return;

    var $body 									= $('body')

    var $layoutElm 							= $('.collection-wrap, .global-header')
    var $layoutWrapElm 					= $('.collection-wrap')
    var $layoutGlobalHeaderElm 	= $('.global-header')
    var $itemContainer 					= $('.ncollection-carousel .ncollection-carousel__itemscontainer')

    var $btnPrev 								= $('.ncollection-carousel__nav__btn-prev')
    var $btnNext 								= $('.ncollection-carousel__nav__btn-next')

    var $slides
    var $currentSlide

    function setupCarousel(data) {
      var selectedProductName = data.motive
      var selectedCategory = data.category

      var categoryProducts = _.find(window.products, function(item) {
        return item.Category === selectedCategory
      })

      var products = categoryProducts.Products.map(function(item, i) {
        item.src = item.Media
        item.index = i
        return item
      })

      products.forEach(function(item) {
        var $itemContainer 	= $('.ncollection-carousel .ncollection-carousel__itemscontainer')
        var $tmplItem 			= $('<div class="ncollection-carousel__item">')
        var $tmplContent 		= $('<div class="ncollection-carousel__item__content">')
        var $tmplLoader 		= $('<div class="ncollection-carousel__spinner">' + '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="2" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>' + '</div>')
        var $tmplImage 			= $('<div class="ncollection-carousel__item__image">')
        var $tmplText 			= $('<div class="ncollection-carousel__item__text">' + '<div class="ncollection-carousel__item__title">' + item.Motive + '</div>' + '<div class="ncollection-carousel__item__articlenum">' + item.Name + '</div>' + '</div>')

        $tmplItem.attr('data-index', item.index)
        $tmplItem.attr('data-motive', item.Motive)
        $tmplContent.append($tmplImage)
        $tmplContent.append($tmplText)
        $tmplContent.append($tmplLoader)
        $tmplItem.append($tmplContent)
        $itemContainer.append($tmplItem)
      })

      var initialSlideIndex = _.findIndex(products, function(item) {
        return selectedProductName === item.Motive
      })

      $slides = $itemContainer.find('.ncollection-carousel__item')
      $currentSlide = getSlideByIndex(initialSlideIndex)
      $currentSlide.css('z-index', $slides.length)

      $btnPrev.show()
      $btnNext.show()

      if ($slides.length === 1) {
        $btnPrev.hide()
        $btnNext.hide()
      }

      trackProductClick(selectedProductName)

      var queue = new createjs.LoadQueue()
      queue.loadManifest(products)
      queue.on('fileload', function(e) {
        var item = e.item
        var $itemElm = getSlideByIndex(item.index)
        $itemElm.addClass('ncollection-carousel__item--loaded')
        var img = $itemElm.find('.ncollection-carousel__item__image')
        img.css('background-image', 'url(' + item.src + ')')
      })
      queue.load()

      $btnPrev.on('click', prevButtonHandler)
      $btnNext.on('click', nextButtonHandler)

    }

    function trackProductClick(productName) {
      royal.tracking.collectionImageClick(productName)
    }

    function changeSlide(index) {

      var nextIndex
      var currentIndex = $currentSlide.data('index')
      var direction = index > currentIndex

      if (direction) {
        nextIndex = (index >= $slides.length)
          ? 0
          : index
      } else {
        nextIndex = (index < 0)
          ? $slides.length - 1
          : index
      }

      $btnPrev.off('click', prevButtonHandler)
      $btnNext.off('click', nextButtonHandler)

      var $nextSlide = getSlideByIndex(nextIndex)
      $slides.css('z-index', 0)
      $currentSlide.css('z-index', $slides.length - 1)
      $nextSlide.css('z-index', $slides.length)

      var $nextSlideContent = $nextSlide.find('.ncollection-carousel__item__content')

      TweenMax.set(
        $nextSlide, direction
        ? {
          x: "100%"
        }
        : {
          x: "-100%"
        })
      TweenMax.set(
        $nextSlideContent, direction
        ? {
          x: "-100%"
        }
        : {
          x: "100%"
        })

      TweenMax.to($nextSlide, 0.4, {x: "0%"})
      TweenMax.to($nextSlideContent, 0.4, {
        x: "0%",
        onComplete: function() {
          $btnPrev.on('click', prevButtonHandler)
          $btnNext.on('click', nextButtonHandler)
        }
      })
      $currentSlide = $nextSlide

      trackProductClick($nextSlide.data('motive'))

    }

    function prevButtonHandler() {
      var index = $currentSlide.data('index')
      changeSlide(index - 1)
    }

    function nextButtonHandler() {
      var index = $currentSlide.data('index')
      changeSlide(index + 1)
    }

    function getSlideByIndex(index) {
      return $slides.filter(function(item) {
        return $(this).data('index') === index
      })
    }

    function openInfoModal(e) {

      royal.layoutHelper.controlBodyOverlay($layoutElm, function(size) {
        $layoutGlobalHeaderElm.css('right', size)
        $layoutWrapElm.css('padding-right', size)
      })

      setupCarousel(e.currentTarget.dataset)

      $('#collection-modal')[0].classList.add('modal-open');
      $body[0].classList.add('collection-disable-background');

      $body.off('keydown', escCloseInfoModal).on('keydown', escCloseInfoModal);
      $itemContainer.swipe("enable");
    };

    function closeInfoModal(e) {
      royal.layoutHelper.clearBodyOverlay($layoutElm, 0, function() {
        $layoutGlobalHeaderElm.css('right', 0)
        $layoutWrapElm.css('padding-right', 0)
      })

      $('#collection-modal')[0].classList.remove('modal-open');
      $body[0].classList.remove('collection-disable-background');
      $('#collection-modal-image').attr('src', '');

      setTimeout(function() {
        $itemContainer.empty()
      }, 300)

      $btnPrev.off('click', prevButtonHandler)
      $btnNext.off('click', nextButtonHandler)
      $itemContainer.swipe("disable");
    }

    function escCloseInfoModal(e) {
      if (e.keyCode == 27) {
        e.preventDefault();
        closeInfoModal(e);
        $body.off('keydown', escCloseInfoModal);
      }
    }

    // Binds events to open the modal with either a click for focus then enter
    $('.ncollection-products').on('click', '.ncollection__product', openInfoModal).on('keydown', '.ncollection__product', function(e) {
      e.keyCode == 13 && openInfoModal(e)
    });
    $('.collection-modal-close').on('click', closeInfoModal);

    $itemContainer.swipe({swipeLeft: nextButtonHandler, swipeRight: prevButtonHandler});

  }

  return {init: init}

})(jQuery)

royal.plainswiper = (function($) {
  "use strict";

  function init() {

    var $plainswiper = $('.plainswiper')

    if ($plainswiper.length === 0)
      return

    var effect = $plainswiper.data('effect') || 'slide'

    var speed = effect === 'fade'
      ? 1400
      : 800

    $plainswiper.each(function(i, elm) {

      var $plainswiperElm = $(elm)

      var $swiperContainer = $(elm).find('.swiper-container')

      var swiper = new Swiper($swiperContainer, {
        autoHeight: true,
        pagination: {
          clickable: true
        },
        speed: speed,
        effect: effect,
        fadeEffect: {
          crossFade: true
        }
      })

      var $pagingSteps 	= $plainswiperElm.find('.swiper__paging__step')
      var $pagingLinks 	= $plainswiperElm.find('.swiper__paging__link')
      var $btnPrev 			= $plainswiperElm.find('.swiper__navbuttons__cell--prev .swiper__navbtn')
      var $btnNext 			= $plainswiperElm.find('.swiper__navbuttons__cell--next .swiper__navbtn')

      $pagingLinks.on('click', function(e) {
        e.preventDefault()
        var $elm = $(this)
        var index = $elm.data('index')
        if (swiper.activeIndex === index)
          return
        swiper.slideTo(index)
      })

      swiper.on('slideChange', slideUpdate)

      $btnPrev.on('click', function() {
        swiper.slidePrev()
      })

      $btnNext.on('click', function() {
        swiper.slideNext()
      })

      function slideUpdate() {
        setPagingIndex(swiper.activeIndex)
        if ($btnPrev.length !== 0)
          $btnPrev.addClass('swiper__navbtn--show')
        if ($btnNext.length !== 0)
          $btnNext.addClass('swiper__navbtn--show')
        if (swiper.activeIndex === 0) {
          $btnPrev.removeClass('swiper__navbtn--show')
        }
        if (swiper.activeIndex === swiper.slides.length - 1) {
          $btnNext.removeClass('swiper__navbtn--show')
        }
      }

      function setPagingIndex(index) {
        var step = $pagingSteps.find('[data-index="' + index + '"]')
        $pagingSteps.removeClass('swiper__paging__step--selected')
        step.parent().addClass('swiper__paging__step--selected')
      }

      slideUpdate()

    })

  }

  return {init: init}
})(jQuery);

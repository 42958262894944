royal.newtimelineslider = (function($) {
  "use strict";

  var ACTIVE_CLASS = 'nts__gallery__slide--active'
  var ACTIVE_SELECTOR = '.' + ACTIVE_CLASS

  function init() {
    var $timelineslider = $('.newtimelineslider')
    if ($timelineslider.length === 0)
      return

    var activeIndex 	= 0
    var $pagingSteps 	= $('.newtimelineslider .swiper__paging__step')
    var $pagingLinks 	= $('.newtimelineslider .swiper__paging__link')
    var $btnPrev 			= $('.newtimelineslider .swiper__navbuttons__cell--prev .swiper__navbtn')
    var $btnNext 			= $('.newtimelineslider .swiper__navbuttons__cell--next .swiper__navbtn')

    var $gallery 			= $('.nts__gallery')
    var $slides 			= $('.nts__gallery .nts__gallery__slide')
    var $currentSlide = $slides.filter(ACTIVE_SELECTOR)
    var $targetSlide

    function prevButtonHandler() {
      if (activeIndex === 0)
        return
      updateSlidesToIndex(activeIndex - 1)
    }

    function nextButtonHandler() {
      if (activeIndex === $slides.length - 1)
        return
      updateSlidesToIndex(activeIndex + 1)
    }

    function pagingClickHandler(e) {
      e.preventDefault()
      var $elm = $(this)
      var index = $elm.data('index')
      updateSlidesToIndex(index)
    }

    function updateSlidesToIndex(index) {

      if (activeIndex === index)
        return

      $targetSlide = $slides.filter(function() {
        return $(this).data('index') === index
      })
      $currentSlide = $slides.filter(ACTIVE_SELECTOR)
      $targetSlide.addClass(ACTIVE_CLASS);

      $currentSlide.css('z-index', 0)
      $targetSlide.css('z-index', 1)

      var direction = (activeIndex < index)
      activeIndex = index

      royal.tracking.timelineClick(++index);

      removeHandlers()
      animateIn(direction)
      animateOut(!direction)
      updateNavigation()
    }

    function preventDefaultHandler(e) {
      e.preventDefault();
    }

    function adjustDirection(obj, direction) {
      obj.x = direction
        ? obj.x
        : '-' + obj.x
      return obj
    }

    function animateIn(direction) {
      var header 				= $targetSlide.find('.nts__event__header')
      var body 					= $targetSlide.find('.nts__event__body')
      var image 				= $targetSlide.find('.nts__event__image')
      var slideElements = [header, body]

      var slideElementsOutProps = {
        opacity: 0,
        x: '20%'
      }
      var slideElementsInsideProps = {
        opacity: 1,
        x: '0%'
      }
      TweenMax.set(slideElements, adjustDirection(slideElementsOutProps, direction))
      TweenMax.staggerTo(slideElements, 0.4, slideElementsInsideProps, 0.1)

      var imageInsideProps = {
        opacity: 1
      }
      var imageOutsideProps = {
        opacity: 0
      }
      TweenMax.set(image, imageOutsideProps)
      TweenMax.to(image, 0.4, imageInsideProps)

    }

    function animateOut(direction) {
      var header 				= $currentSlide.find('.nts__event__header')
      var body 					= $currentSlide.find('.nts__event__body')
      var image 				= $currentSlide.find('.nts__event__image')
      var slideElements = [header, body]

      var slideElementsInsideProps = {
        opacity: 1,
        x: '0%'
      }
      var slideElementsOutProps = {
        opacity: 0,
        x: '20%'
      }

      TweenMax.set(slideElements, slideElementsInsideProps)
      TweenMax.staggerTo(slideElements, 0.4, adjustDirection(slideElementsOutProps, direction), 0.1, animationComplete)

      var imageInsideProps = {
        opacity: 1
      }
      var imageOutsideProps = {
        opacity: 0
      }
      TweenMax.set(image, imageInsideProps)
      TweenMax.to(image, 0.4, imageOutsideProps)
    }

    function animationComplete() {
      $currentSlide.removeClass(ACTIVE_CLASS);
      setHandlers()
    }

    function updateNavigation() {
      setPagingIndex(activeIndex)
      $btnPrev.addClass('swiper__navbtn--show')
      $btnNext.addClass('swiper__navbtn--show')
      if (activeIndex === 0) {
        $btnPrev.removeClass('swiper__navbtn--show')
      }

      if (activeIndex === $slides.length - 1) {
        $btnNext.removeClass('swiper__navbtn--show')
      }
    }

    function setPagingIndex(index) {
      var step = $pagingSteps.find('[data-index="' + index + '"]')
      $pagingSteps.removeClass('swiper__paging__step--selected')
      step.parent().addClass('swiper__paging__step--selected')
      // royal.tracking.timelineClick(step.parent().find(".swiper__paging__title").text())
    }

    function resizeHandler() {
      $slides.css("height", "auto")
      var a = $slides.map(function() {
        return $(this).height()
      })
      var highest = Math.max.apply(Math, a)
      $gallery.css("height", highest)
      $slides.css("height", highest);
    }

    function setHandlers() {
      $pagingLinks.on('click', pagingClickHandler)
      $btnPrev.on('click', prevButtonHandler)
      $btnNext.on('click', nextButtonHandler)
      $gallery.swipe("enable");
    }

    function removeHandlers() {
      $pagingLinks.unbind("click", pagingClickHandler).bind("click", preventDefaultHandler)
      $btnPrev.unbind('click', prevButtonHandler).bind("click", preventDefaultHandler)
      $btnNext.unbind('click', nextButtonHandler).bind("click", preventDefaultHandler)
      $gallery.swipe("disable");
    }

    $(window).on("resize", _.throttle(resizeHandler, 20))

    $gallery.swipe({swipeLeft: nextButtonHandler, swipeRight: prevButtonHandler});

    updateNavigation()
    resizeHandler()
    setHandlers()

  }

  return {init: init}
})(jQuery);

royal.experience = (function ($) {
  "use strict";

  var IMAGE_PATH 									= window.SequencePath || '/assets/img/plate_sequence/'
  var IMAGE_TITLE_PREFIX 					= 'floraPlate_'
  var IMAGE_SEQUENCE_LENGTH 			= 180
  var IMAGE_CANVAS_WIDTH		 			= 1024 // Remember to set correct canvas size - based on image size
  var IMAGE_CANVAS_HEIGHT 				= 768
  var SCROLL_THROTTLE 						= 20
  var RESIZE_THROTTLE 						= 60
  var SCROLL_MAGIC_OFFSET_HEIGHT 	= -200
  var ANIMATION_DURATION 					= 600
  var INITIAL_FADE_IN_DURATION 		= 2000

  var
    loadedImages,
    imagesSourceList,
    canvas,
    ctx,
    twObj,
    lastSeqIndex,
    throttledUpdate,
    smController,
    smScene,
    $experienceElm,
    $experienceWrapperElm

	function init() {

	  $experienceElm = $('.experience-teaser')
	  $experienceWrapperElm = $('.experience-teaser__image-wrapper')

	  if ($experienceElm.length === 0)
	    return

	  canvas = document.getElementById('experience-teaser-canvas')
	  ctx = canvas.getContext('2d')
	  loadedImages = []
	  twObj = {
	    currentSeqIndex: 0
	  }
	  lastSeqIndex = 0
	  imagesSourceList = Array.apply(null, {length: IMAGE_SEQUENCE_LENGTH}).map(function(_, i) {
	    var num = i + 1
	    return [IMAGE_PATH, IMAGE_TITLE_PREFIX, num, '.jpg'].join('')
	  })

	  throttledUpdate = _.throttle(update, SCROLL_THROTTLE)
	  preloadInitialImage()

		$experienceElm.find('.button').on('click', function() {
      royal.tracking.experienceTracking();
		})
	}

	function update(event) {
	  var seqIndex = Math.round(IMAGE_SEQUENCE_LENGTH * event.progress)
	  if (lastSeqIndex !== seqIndex) {
	    TweenMax.to(twObj, ANIMATION_DURATION / 1000, {
	      currentSeqIndex: seqIndex - 1,
	      roundProps: 'currentSeqIndex',
	      overwrite: 'all',
	      ease: Power1.easeOut,
	      onUpdate: drawImage
	    })
	  }
	  lastSeqIndex = seqIndex
	}

	function setupScrollSequencer() {
	  smController = new ScrollMagic.Controller()
	  smScene = new ScrollMagic.Scene({triggerElement: '.experience-teaser'}).on('progress', throttledUpdate).offset(window.innerHeight / 4)
	  // .addIndicators({name: 'Plate'})
	    .addTo(smController)
	  updateScrollMagic()
	}

	function resizeHandler() {
	  var cellWidth = $experienceWrapperElm.innerWidth()
	  var ratio = cellWidth / IMAGE_CANVAS_WIDTH
	  TweenMax.set($experienceWrapperElm, {
	    scale: ratio,
	    transformOrigin: "left top"
	  })
	  updateScrollMagic()
	}

	function updateScrollMagic() {
	  if (!smScene)
	    return
	  var winHeight = $(window).innerHeight()

	  var offset = 0

	  switch (true) {
	    case winHeight < 400:
	      offset = -200
	      break
	    case winHeight < 500:
	      offset = -100
	      break
	    case winHeight < 700:
	      offset = 0
	      break
	    default:
	      offset = 0
	  }

	  //console.log('of--:', offset);

	  smScene.duration($experienceElm.innerHeight() / 3)
	  //.offset(offset)
	}

	function setupCanvas() {
	  var img = loadedImages[0]
	  var $window = $(window)
	  canvas.width = IMAGE_CANVAS_WIDTH
	  canvas.height = IMAGE_CANVAS_HEIGHT
	  $window.on('resize', _.throttle(resizeHandler, RESIZE_THROTTLE), this)
	  resizeHandler()
	}

	function drawImage() {
	  var img = loadedImages[twObj.currentSeqIndex]
	  if (!img)
	    return
	  requestAnimationFrame(function() {
	    ctx.drawImage(img, 0, 0, IMAGE_CANVAS_WIDTH, IMAGE_CANVAS_HEIGHT)
	  })
	}

	function handleFileLoad(e) {
	  var item = e.item
	  var type = item.type
	  if (type === createjs.LoadQueue.IMAGE) {
	    loadedImages.push(e.result)
	  }
	}

	function preloadInitialImage() {
	  var queue = new createjs.LoadQueue()
	  queue.loadFile(imagesSourceList[0])
	  queue.on('fileload', function(e) {
	    handleFileLoad(e)
	    setupCanvas()
	    drawImage()
	    TweenMax.to($experienceWrapperElm, INITIAL_FADE_IN_DURATION / 1000, {opacity: 1})
	  }, this)
	  queue.load()
	  queue.on('complete', preloadImages, this)
	}

	function preloadImages() {
	  var queue = new createjs.LoadQueue()
	  queue.loadManifest(imagesSourceList)
	  queue.on('fileload', handleFileLoad, this)
	  queue.on('complete', setupScrollSequencer, this)
	  queue.load()
	}

	return {init: init}
})(jQuery);

royal.collection = (function($) {
  "use strict";
  function init() {
    if ($('.collection-wrap').length === 0)
      return;

    function openInfoModal(e) {
      setTextPos();

      var data 				= e.currentTarget.dataset;
      var motive 			= data.motive;
      var formNumber 	= data.formnumber;
      var windowWidth = window.innerWidth;
      var resizer 		= '?width=';

      if (windowWidth > 1440) {
        resizer += '1920';
      } else if (windowWidth > 1024) {
        resizer += '1440';
      } else {
        resizer += '1024';
      }

      // Adds class to open the modal
      $('#collection-modal')[0].classList.add('modal-open');
      $('body')[0].classList.add('collection-disable-background');

      // Adds information to the modal
      $('#collection-modal-motive').text(motive);
      $('#collection-modal-formNumber').text(formNumber);
      var src = $(e.currentTarget).find('.collection-image--container').data('image') + resizer;
      $('#collection-modal-image').attr('src', src);

      // Binds events to be able to close the modal with esc
      $('body').off('keydown', escCloseInfoModal).on('keydown', escCloseInfoModal);
    };

    function closeInfoModal(e) {
      $('#collection-modal')[0].classList.remove('modal-open');
      $('body')[0].classList.remove('collection-disable-background');
      $('#collection-modal-image').attr('src', '');
    }

    function escCloseInfoModal(e) {
      if (e.keyCode == 27) {
        // Esc key == 27
        e.preventDefault();
        closeInfoModal(e);
        $('body').off('keydown', escCloseInfoModal);
      }
    }

    $(window).on('resize', function() {
      setTextPos();
    })

    function setTextPos() {

      setTimeout(function() {
        // console.log('offse123xz');
        var offset = document.getElementById('collection-modal-image').getBoundingClientRect().top;
        var setHeight = $('#collection-modal-image').height();

        $('.collection-modal-text').css('top', (offset + setHeight - 60) + 'px');
      }, 500);
    }

    // Binds events to open the modal with either a click for focus then enter
    $('.ncollection-products').on('click', '.ncollection-item', openInfoModal).on('keydown', '.ncollection-item', function(e) {
      e.keyCode == 13 && openInfoModal(e)
    });
    $('.collection-modal-close').on('click', closeInfoModal);
    $('#collection-modal').on('click', closeInfoModal);
  }

  return {init: init}

})(jQuery)

royal.history = (function($) {
  "use strict";

  function init() {

    if ($('.history').length === 0)
      return

    var $layoutElm = $('.story-page, .global-header')
    var $layoutWrapElm = $('.story-page')
    var $layoutGlobalHeaderElm = $('.global-header')

    new Swiper('.history__media__swiper.swiper-container--plainswiper', {
      loop: true,
      autoplay: true,
      pagination: {
        el: '.history__media__swiper-pagination',
        clickable: true
      },
      effect: 'fade',
      speed: 2000
    })

    var $view = $('.modal__history')[0]
    var $chapters = $('.modal__history li')

    var scouts = $chapters.map(function(i, chapter) {
      var virtualUrl = $(chapter).data('virtual-page-url')
      var virtualTitle = $(chapter).data('virtual-page-title')
      return {
        scout: scrollscout.create(chapter, $view),
        virtualUrl: virtualUrl,
        virtualTitle: virtualTitle
      }
    })

    // Hookup scroll event
    $('.modal__history').scroll(function(e) {
      if ($('.modal__history').hasClass('display--modal'))
        setIndicators()
    })

    // Open modal functionalities
    $('.modal__history--button').on('click', function() {

      royal.layoutHelper.controlBodyOverlay($layoutElm, function(size) {
        $layoutGlobalHeaderElm.css('right', size)
        $layoutWrapElm.css('padding-right', size)
      })

      $('body').addClass('overflow--hidden');
      royal.tracking.readTheStoryClick();
      $('.modal__history, .modal__close, .history--indicatorwrap').addClass('display--modal');
      $('.modal__history').addClass('unhide--modal');

      scouts.each(function(i, sc) {
        var unsub = sc.scout.addTrigger('trigger' + i).subscribe(function() {
          royal.tracking.craftReadTheStoryScrollToChapter(sc.virtualUrl, sc.virtualTitle)
          unsub()
        })
        sc.scout.update()
        return unsub
      })

      setTimeout(function() {
        $('.history--indicatorwrap .historyslider__indicator').each(function(i) {
          var $item = $(this);

          setTimeout(function() {
            $item.animate({
              width: 40
            }, 250);
          }, 150 * i);
        });
      }, 1000);

    });

    // Close modal functionalities
    $('.modal__close').on('click', function() {
      $('body').removeClass('overflow--hidden');
      $('.display--modal').removeClass('display--modal')

      $('.historyslider__indicator').animate({
        width: 0
      }, 150);

      setTimeout(function() {
        $('.modal__history').removeClass('unhide--modal')
      }, 500)

      royal.layoutHelper.clearBodyOverlay($layoutElm, 0, function() {
        $layoutGlobalHeaderElm.css('right', 0)
        $layoutWrapElm.css('padding-right', 0)
      })

    })

    // Nav to given element
    $('.historyslider__indicator').on('click', function() {
      var elem = $('.historyslider__item').eq($('.historyslider__indicator').index($(this)));

      $('.modal__history').animate({
        scrollTop: $('.modal__history').scrollTop() + (elem.offset().top - elem.height())
      }, 500)
    })
  }

  function setIndicators() {
    $('.historyslider__item--wrap').each(function(i) {
      if ($(this).visible(true) && !$(this).hasClass('item--inview')) {

        $(this).addClass('item--inview');

        $('.historyslider__indicator').eq(i).addClass('historyslider__indicator--active')
      }
    })
  }

  return {init: init}

})(jQuery);

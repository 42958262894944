royal.timeline = (function($) {
  "use strict";

  function init() {

    var $timelineslider = $('.timelineslider')

    if ($timelineslider.length === 0)
      return

    var swiper = new Swiper('.timelineslider .ts__eventlist', {
      //autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })

    var $pagingSteps 	= $('.timelineslider .swiper__paging__step')
    var $pagingLinks 	= $('.timelineslider .swiper__paging__link')
    var $btnPrev 			= $('.timelineslider .swiper__navbuttons__cell--prev .swiper__navbtn')
    var $btnNext 			= $('.timelineslider .swiper__navbuttons__cell--next .swiper__navbtn')

    $pagingLinks.on('click', function(e) {
      e.preventDefault()
      var $elm = $(this)
      var index = $elm.data('index')
      if (swiper.activeIndex === index)
        return
      swiper.slideTo(index)
    })

    $btnPrev.on('click', function() {
      swiper.slidePrev()
    })

    $btnNext.on('click', function() {
      swiper.slideNext()
    })

    swiper.on('slideChange', slideUpdate)

    function slideUpdate() {
      setPagingIndex(swiper.activeIndex)
      $btnPrev.addClass('swiper__navbtn--show')
      $btnNext.addClass('swiper__navbtn--show')
      if (swiper.activeIndex === 0) {
        $btnPrev.removeClass('swiper__navbtn--show')
      }
      if (swiper.activeIndex === swiper.slides.length - 1) {
        $btnNext.removeClass('swiper__navbtn--show')
      }
    }

    function setPagingIndex(index) {
      var step = $pagingSteps.find('[data-index="' + index + '"]')
      $pagingSteps.removeClass('swiper__paging__step--selected')
      step.parent().addClass('swiper__paging__step--selected')

    }

    slideUpdate()
  }

  return {init: init}
})(jQuery);

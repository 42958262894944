royal.inspiration = (function($) {
  "use strict";

  function init() {

    var $inspiration = $('.inspiration')

    if ($inspiration.length === 0)
      return

    var swiper = new Swiper('.inspiration .swiper-container', {
      autoHeight: true,
      allowTouchMove: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      effect: 'fade'
    })

    var $pagingSteps = $('.inspiration .swiper__paging__step')
    var $pagingLinks = $('.inspiration .swiper__paging__link')
    var $btnPrev = $('.inspiration .swiper__navbuttons__cell--prev .swiper__navbtn')
    var $btnNext = $('.inspiration .swiper__navbuttons__cell--next .swiper__navbtn')

    $pagingLinks.on('click', function(e) {
      e.preventDefault()
      var $elm = $(this)
      var index = $elm.data('index')
      if (swiper.activeIndex === index)
        return
      swiper.slideTo(index)
    })

    $btnPrev.on('click', function() {
      swiper.slidePrev()
    })

    $btnNext.on('click', function() {
      swiper.slideNext()
    })

    swiper.on('slideChange', slideUpdate)

    function slideUpdate() {
      setPagingIndex(swiper.activeIndex)
      $btnPrev.addClass('swiper__navbtn--show')
      $btnNext.addClass('swiper__navbtn--show')
      if (swiper.activeIndex === 0) {
        $btnPrev.removeClass('swiper__navbtn--show')
      }
      if (swiper.activeIndex === swiper.slides.length - 1) {
        $btnNext.removeClass('swiper__navbtn--show')
      }

      var trackingSlide = swiper.activeIndex + 1;

      royal.tracking.inspirationClick(trackingSlide);
    }

    function setPagingIndex(index) {
      var step = $pagingSteps.find('[data-index="' + index + '"]')
      $pagingSteps.removeClass('swiper__paging__step--selected')
      step.parent().addClass('swiper__paging__step--selected')
    }

    slideUpdate()

  }

  return {init: init}

})(jQuery);
